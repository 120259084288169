<template>
  <van-nav-bar
    title="商家选择"
    left-text=""
    right-text="退出"
    left-arrow
    @click-left="onClickLeft"
    @click-right="onClickRight"
    safe-area-inset-top
    :border="false"
  />
  <div class="merchant-main">
    <div class="user-info">
      <div class="pic">
        {{ session.userSessionDto.userName.substring(0, 1) }}
      </div>
      <div class="info">
        <div>{{ session.userSessionDto.userName }}</div>
        <div>{{ session.userSessionDto.phone }}</div>
      </div>
    </div>
    <div class="main-select">
      <div class="sub-title">我的店铺</div>
      <div>
        <van-cell
          title="单元格"
          size="large"
          is-link
          v-for="item in merchantList"
          :key="item"
          @click="handleSelectMerchant(item)"
          style="margin-bottom:16px;border-radius: 4px;"
        >
          <template #title>
            <div class="merchant-name">{{ item.merchantName }}</div>
          </template>
        </van-cell>
      </div>
    </div>
  </div>
  <van-popup
    round
    :show="show"
    :close-on-click-overlay="false"
    @click-overlay="show = false"
  >
    <div class="wrapper">
      <div>
        <div class="title">选择物料库</div>
        <div
          style="margin-top:10px"
          v-for="item in materialStockList"
          :key="item.pmsId"
        >
          <van-radio-group v-model="checked">
            <div class="materialStockName">
              <van-radio :name="item.pmsId">
                <div>
                  {{ item.materialStockName }}
                </div>
              </van-radio>
            </div>
          </van-radio-group>
        </div>
      </div>

      <div class="bottom-btns">
        <van-button block round style="flex:1" @click="show = false"
          >取消</van-button
        >
        <van-button
          block
          round
          style="flex:1"
          type="primary"
          @click="handleComfirm"
          >确定</van-button
        >
      </div>
    </div>
  </van-popup>
</template>

<script setup>
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import { enterMerchant, indexMyMerchant } from "@/api/user";
import { computed } from "@vue/runtime-core";
import { useRouter } from "vue-router";
import { initMaterialInfoByMerchant } from "../../api/user";

const store = useStore();
const router = useRouter();
const merchantList = ref([]);
const onClickLeft = () => {
  router.back();
};
const session = computed(() => store.getters.session);
const onClickRight = () => {
  store.dispatch("LogOut").then(() => {
    location.href = "/";
  });
};
const token = computed(() => store.getters.token);
const materialStockList = ref([]);
const show = ref(false);
const merchantId = ref(null);
const merchantName = ref(null);
const checked = ref(null);
const handleSelectMerchant = async (item) => {
  merchantId.value = item.merchantId;
  merchantName.value = item.merchantName;
  // let param = {
  //   token: token.value,
  //   merchantId: item.merchantId,
  //   merchantName: item.merchantName,
  // };
  // await enterMerchant(param);
  // store.commit("SET_MERCHANT_ID", item.merchantId);
  // store.commit("SET_MERCHANT_NAME", item.merchantName);
  let res = await initMaterialInfoByMerchant({
    token: token.value,
    merchantId: item.merchantId,
  });
  materialStockList.value = res.materialStockList;
  show.value = true;
};
const handleComfirm = async () => {
  let param = {
    token: token.value,
    merchantId: merchantId.value,
    merchantName: merchantName.value,
  };
  await enterMerchant(param);
  store.commit("SET_MERCHANT_ID", merchantId.value);
  store.commit("SET_MERCHANT_NAME", merchantName.value);
  store.commit("SET_MATERIALID", checked.value);
  show.value = false;
  router.replace("/");
};
const loadMyMerchantList = async () => {
  if (!token.value) {
    router.replace("/login");
    return false;
  }
  let data = await indexMyMerchant({
    merchantType: 2,
    token: token.value,
  });
  if (data) {
    merchantList.value = data;
  }
};
loadMyMerchantList();
</script>

<style lang="scss" scoped>
.merchant-main {
  background: #f1f1f5;
  padding: 16px;
  text-align: left;
  .user-info {
    display: flex;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 1px 2px rgba(151, 151, 151, 0.1);
    border-radius: 6px;
    padding: 20px 16px;
    .pic {
      width: 49px;
      height: 49px;
      line-height: 49px;
      font-weight: 500;
      font-size: 24px;
      color: #fff;
      background: #289fff;
      border-radius: 50%;
      text-align: center;
    }
    .info {
      color: #333333;
      font-weight: 600;
      font-size: 14px;
      margin-left: 16px;
    }
  }
  .main-select {
    margin: 27px 0 30px 0;
    .sub-title {
      color: #333333;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 10px;
    }
    .merchant-name {
      color: #333333;
      font-weight: 600;
      font-size: 14px;
    }
  }
}
.wrapper {
  min-height: 300px;
  padding: 8px;
  width: 300px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .title {
    color: #171725;
    font-size: 16px;
 
  }
  .bottom-btns {
    display: flex;
    height: 50px;
  }
}
.materialStockName {
  font-size: 16px;
  padding: 8px;
}
</style>
